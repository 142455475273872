<template>
  <div class="ticket__item row">
    <div class="ticket__item-stock col-md-2 d-flex">
      <span class="d-md-none">{{ $t('Request number') }}: </span>
      <b>{{ ticket.id }}</b>
    </div>
    <div class="ticket__item-stock col-md-2 d-flex">
      <span class="d-md-none">{{ $t('Subject') }}: </span>
      {{ ticket.subject }}
    </div>
    <div class="col-md-2 ticket__item-stock d-flex">
      <span class="d-md-none">{{ $t('Order number') }}: </span>
      {{ ticket.order ? ticket.order : '' }}
    </div>
    <div class="col-md-2 ticket__item-stock d-flex">
        <span class="d-md-none">{{ $t('Date') }}: </span>
      {{ formatDate(ticket.created_at) }}
    </div>
    <div class="ticket__item-stock col-md-2 d-flex">
      <span class="d-md-none">{{ $t('Update on') }}: </span>
      {{ formatDate(ticket.updated_at) }}
    </div>
    <div class="ticket__item-stock col-md-2 d-flex">
      <span class="d-md-none">{{ $t('Status') }}: </span>
      <b class="text-uppercase">{{ $t('zendesk.'+ticket.status)}}</b>
    </div>
    <div class="ticket__item-actions">
      <div class="ticket__item-view">
        <router-link :to="{name:'ticketDetail', params: {id: ticket.id}}" class="bcm-link bcm-link-underline-primary">
          {{ $t('View') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'MyTicket',
  props: {
    ticket: {
      type: Object
    }
  },
  methods: {
    formatDate: function (date) {
      return dayjs(date).format(this.$t('dateFormat'))
    }
  },
  computed: {
  }
}
</script>
