import { mapGetters } from 'vuex'

export const UserTickets = {
  name: 'UserTickets',
  ...mapGetters('zendesk', ['ticketHistory']),
  methods: {
    getTicketsFromState () {
      return this.$store.state.zendesk.userTickets
    }
  },
  computed: {
    getTickets () {
      return this.getTicketsFromState()
    }
  }
}
