<template>
  <div class="help-box">
    <div class="help-box__header">
      <slot name="header">
        <h3 class="title">
          {{ $t('helpBox.title') }}
        </h3>
      </slot>
    </div>
    <div class="help-box__content">
      <slot name="content">
        <p class="my-requests__intro-text ">{{ $t('helpBox.subtitle') }}</p>
      </slot>
    </div>
    <div class="help-box__footer">
      <slot name="footer">
        <router-link :to="{ name: 'ContactUs' }" v-t="'helpBox.buttonText'" class="btn btn-none bcm-link bcm-link-underline-primary"/>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpBox'
}
</script>
